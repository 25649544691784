/**
 * Site Variables
 *
 * @package 	kms-lima
 * @author  	Mayeenul Islam
 */

$base-color: 		#2c3e50;
$secondary-color: 	#34495e;
$link-color:		$base-color;
$hover-color:		$secondary-color;

$black-color:		#333;
$red-color:			#e53d37;

/* theme colors */
$theme-default:		#425f64;
$theme-green:		#223c34;
$theme-violet:		#464056;

/**
 * ReColoring...................
 * 
 * #594057 (dull violet)
 * #563761 (bright violet)
 * #547980 (cyan)
 * #5968b1 (blue)
 * #5C5470 (dull blue)
 * #4850b9 (dark blue)
 * #222831 (blackish)
 * #56132A (reddish)
 */

/**
 * Chosen
 *
 * #425f64 - teal (default)
 * #223c34 - green
 * #446cb3 - blue
 * #464056 - violet
 * #46a546 - light green
 */
