/**
 * Custom Styles
 *
 * For any custom styles, you want to add/modify, please place
 * here in this file.
 *
 * @package 	kms-lima
 */

//custom CSS rules here
