/**
 * Site Styles
 *
 * @package 	kms-lima
 * @author  	Mayeenul Islam
 */

// ----------------------
// GLOBAL CSS
// ----------------------

body{
	background-color: #f2f2f2;
	color: $black-color;
}

a{
	color: $link-color;
}

img{
	max-width: 100%;
	height: auto;
}


// ----------------------
// COMMON CSS
// ----------------------

.site-container{
	//margin-top: 10px;
	//margin-bottom: 10px;
	
	// added according to http://ryanfait.com/sticky-footer/
	min-height: 100%;
	height: auto !important;
	height: 100%;
	margin: 0 auto -4em;
}

.mobile-container{
	padding-top: 20px;
}

.content-wrapper{
	max-width: 1200px;
}

.btn.btn-with-icon{
	.fa{
		margin-right: 5px;
	}
}

.behave-table{
	display: table;
	width: 100%;
	height: 100%;
}

.behave-table-cell{
    display: table-cell;
    vertical-align: middle;
}


// ----------------------
// WEB LOGIN CSS
// ----------------------
html.login-registration,
body.login-registration{
	height: 100%;
	margin: 0;
}

body.login-registration{
	.site-title{
		font-size: 18px;
		font-weight: 700;
		//margin-bottom: 0;
	}

	.site-description{
		font-size: 13px;
		margin-top: 0;
	}

	.logo{
		width: 100px;
	}
}

.logo-area{
	display: block;
}

.login-area{
	position: relative;
	width: 95%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 20px rgba(0,0,0,0.4);

    .input-group-addon{
		min-width: 105px;
		text-align: left;

		.fa-envelope{
			font-size: 12px;
		}
	}
}
@media (min-width: 769px) {
	.login-area{
    	width: 66.66666667%;
	}
	.admin-login-area {
		width: 35%;
	}
}

#login-footer{
	padding-top: 10px;
	font-size: 13px;
}


/** Templating **/

@media (min-width: 769px) {
	.login-logo-container,
	.login-form-container{
		float: left;
		width: 41.66666667%;
	}
	.admin-login{
		width: 100%;
	}
}

.login-logo-container,
.login-form-container{
	position: relative;
	clear: both;
	padding: 20px;
}

.login-logo-container{
	padding-bottom: 0;
}

.login-form-container{
	padding-top: 0;
}


/** Login Form **/

.login-form{
	margin-top: 20px;
	margin-bottom: 40px;
	
	.login-head{
		font-size: 18px;
	}
}

.section-head-border{
	max-width: 100px;
	width: 12%;
	height: 2px;
	background-color: #e5322e;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 0;
}

/** User Tools **/

.user-tools{
	margin-top: 20px;
	margin-bottom: 20px;

	.icons{
		color: #666;
	}
}

.help-btn{
	padding: 6px 30px;
}

/** Login Right */

.login-right{
	background-image: url('../images/login-background.png44');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: bottom center;
	color: #fff;

	&:after{
		content: "";
		display: block;
		background-color: rgba(43,35,34,0.85);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
	}

	*{
		position: relative;
		z-index: 1;
	}
}

@media (min-width: 769px) {
	.login-right{
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		width: 58.33333333%;
	}
}

@media (max-width: 768px) {
	.login-right{
		position: relative;
		background-size: cover;
	}
}

.login-intro{
	padding: 20px;
}

.login-brief{
	p{
		margin-bottom: 40px;
		font-size: 18px;
	}
}

.apply-button{
	font-weight: 700;
	padding: 8px 25px;
}

.btn-renew{
	background-color: rgba(188, 61, 61, 0.7);
	color: #eee;
	border-color: rgba(0, 0, 0, 0.4);

	&:focus,
	&:active,
	&:hover{
		background-color: rgba(188, 61, 61, 0.8);
		color: #fff;
		border-color: rgba(0, 0, 0, 0.5);
	}
}

.btn-android{
	background-color: rgba(100, 39, 79,0.9);
	color: #bbb;
	border-color: rgba(0, 0, 0, 0.4);

	&:focus,
	&:active,
	&:hover{
		background-color: rgba(188, 61, 61, 0.8);
		color: #fff;
		border-color: rgba(0, 0, 0, 0.5);
	}
}

.btn-rounded{
	border-radius: 20px;
}

.site-credit{
	margin-top: 10px;
	color: #999;

	a{
		color: #666;
	}
}


// ----------------------
// SITE CSS
// ----------------------

#site-header{
	background-color: $base-color;
	color: #fff;
	padding: 10px 20px;

	&.affix{
		top: 0;
		left: 0;
		right: 0;
		z-index: 9999; /* fourth level */
	}
}
.site{
	.logo{
		float: left;
		width: 45px;
		margin-right: 10px;
	}

	.site-title,
	.site-description{
		margin: 0;
	}

	.site-title{
		font-size: 27px;
		font-weight: 700;
	}

	.site-description{
		font-size: 13px;
	}
}

@media (max-width: 768px){
	.header-left{
		text-align: center;
	}
}
.header-right{
	text-align: center;
	padding: 10px 0;
}

@media (min-width: 769px) {
	.header-right{
		text-align: right;
		padding: 0;
	}
}

.navbar-toggle-base{
	border-color: #333;

	&:focus,
	&:hover{
		background-color: #333;
	}

	.icon-bar{
		background-color: #fff;
	}
}

#header-nav{
	.navbar-collapse{
		border: 0;
		box-shadow: 0 0 0 rgba(0,0,0,0);
	}
}

#navbar-to-toggle{
	&>ul>li{
		&>a{
			color: #fff;
			background: transparent;
		}

		&.open>a{
			background-color: darken( $base-color, 10% );
		}
	}

	li.highlight-menu{
		a{
			background-color: #dd5448;
			color: #fff;

			&:hover{
				background-color: #333;
			}
		}
	}

	.dropdown-menu .divider{
		margin: 2px 0;
	}

	@media (max-width: 768px) {
		.dropdown-menu>li>a{
			color: #e1e1e1;
			text-align: center;

			&:hover{
				background-color: #333;
			}
		}

		.dropdown-menu .divider{
			background-color: #333;
		}
	}
	
}

#content{
	padding: 20px;
}

// ----------------------
// Dashboard
// ----------------------


// added according to http://ryanfait.com/sticky-footer/
html,
body.site.dashboard,
body.site.attachment{
	height: 100%;
}

.dashboard-blank-head{
	padding: 10px 15px;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
	font-size: 18px;
	background-color: #fff;
	margin-top: 0;
}

.dashboard-card{
	background-color: #fff;
	padding: 20px;
	//margin-bottom: 10px;
	border: 1px solid #e1e1e1;
	border-radius: 3px;

	.application-icon{
		font-size: 35px;
		color: #aaa;
		margin-bottom: 20px;
	}

	&.draft-card{
		color: #777;
		background-color: #ddd;
		border: 1px solid #ccc;

		.application-icon{
			color: #aaa;
		}
	}
}

.application-state-block{
	.application-title{
		margin-top: 0;
		font-weight: bold;

		small{
			font-size: 10px;
		    vertical-align: middle;
		    display: inline-block;
		    padding: 9px 7px;
		}
	}

	ul{
		margin-bottom: 0;
		padding-left: 20px;

		li{
			margin-bottom: 10px;

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}

.dash-panel-user-info{
	border: 1px solid #e1e1e1;

	.panel-heading{
		border-bottom: 1px solid #e1e1e1;
	}
}

.dash-username{
	margin-top: 0;
}

.index-column{
	font-size: 20px;
	font-weight: bold;
}

.index-number{
	background-color: lighten( $base-color, 20% );
	color: #fff;
	text-align: center;
	padding: 5px 10px;
	vertical-align: middle;
	border-radius: 100px;
	display: inline-block;
	margin-bottom: 10px;
	min-width: 45.56px;
}

.dhara{
	//color: darken( $base-color, 5% );
	color: #fff;
	font-size: 80%;
}

.proprietor-block-head{
	border-bottom: 1px solid #ccc;
	padding-bottom: 5px;
	margin-top: 0;
	font-size: 14px;
	color: #666;
}

.panel-bold{
	border-color: darken( $base-color, 10% );

	.panel-heading{
		background-color: darken( $base-color, 10% );
		color: #fff;
	}

	.index-number{
		background-color: darken( $base-color, 10% );
	}
}

.repeater-card{
	display: block;
	margin-bottom: 20px;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

.has-error .btn-file{
	border-color: $btn-danger-border;
}

/** Copied from IKMS */

.establishment-title{
	font-size: 18px;
	margin-top: 0;

	a{
		text-decoration: none;
	}
}

.application-title{
	font-size: 16px;
	margin-top: 0;
}

.application-state-block{
	.application-title{
		margin-top: 0;
		font-weight: bold;

		small{
			font-size: 10px;
			vertical-align: middle;
			display: inline-block;
			padding: 9px 7px;
		}
	}

	ul{
		margin-bottom: 0;
		padding-left: 20px;

		li{
			margin-bottom: 10px;

			&:last-of-type{
				margin-bottom: 0;
			}
		}
	}
}

.establishment-cards{
	border-bottom: 1px solid #e1e1e1;
	padding-top: 15px;

	&:first-of-type{
		padding-top: 0;
	}

	&:last-of-type{
		border-bottom-width: 0;
	}

	.app-icon{
		font-size: 35px;
		color: #aaa;
		margin-bottom: 20px;
	}
}

.application-holder{
	box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
	background-color: #f2f2f2;
}

.application-cards{
	border-bottom: 1px solid #e1e1e1;
	padding-top: 15px;

	&:last-of-type{
		border-bottom-width: 0;
	}

	&:first-of-type{
		border-top: 1px solid #e1e1e1;
	}
}

.application-utility{
	li i{
		margin-right: 10px;
	}
}

.dash-panel-application-info,
.dash-panel-tools,
.dash-panel-user-info{
	border: 1px solid #e1e1e1;

	.panel-heading{
		border-bottom: 1px solid #e1e1e1;
	}
}

.dash-application-label{
	font-weight: 600;
	margin-bottom: 5px;
}

.btn-dash-tool{
	text-align: center;
	white-space: pre-line;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-bottom: 10px;

	[class*="icon-"]{
		font-size: 30px;
		margin-bottom: 5px;
	}
}

.btn-application-tool-group{

	.btn-application-tool{
		background-color: transparent;

		&.active,
		&:active{
			box-shadow: 0 0 0 transparent;
		}
	}

	&.open .dropdown-toggle{
		box-shadow: 0 0 0 transparent;
	}
}

.dash-username{
	margin-top: 0;
}

/** Application Navigation **/

#application-navigation{
	margin-top: 20px;
	margin-bottom: 20px;
}

.nav-pills{

	&>li{
		&>a{
			background-color: #f2f2f2;
			border: 1px solid #e1e1e1;
		}
	}

	&>li.active{
		&>a,
		&>a:focus,
		&>a:hover{
			background-color: $base-color;
		}
	}
}

.panel-base,
.panel-theme{
	border-color: lighten( $base-color, 25% );

	.panel-heading{
		background-color: lighten( $base-color, 20% );
		color: #fff;
	}
}

.panel-heading h3{
	margin: 0;
	font-size: 18px;
}

#form-nav{
	//background-color: #ededed;
	border-top: 1px solid #e1e1e1;
	border-bottom: 1px solid #e1e1e1;
	padding: 5px 0;
	//border-radius: 4px;
}

.section-head-border{
	display: inline-block;
	max-width: 100px;
	width: 12%;
	height: 2px;
	background-color: #e5322e;
	margin-top: 10px;
	margin-bottom: 20px;
	border: 0;

	&.section-head-border-center{
		display: block;
		margin-left: auto;
		margin-right: auto;
		background-color: darken( $base-color, 10% );
	}
}




// ----------------------
// FOOTER SECTION
// ----------------------

.site-footer{
	position: relative;
	display: block;
	background-color: $base-color;
	color: #ccc;
	padding: 5px 20px;
	clear: both; // added according to http://ryanfait.com/sticky-footer/
}

// added according to http://ryanfait.com/sticky-footer/
.site-footer,
.push {
	height: 4em;
}

.copyright,
.site-credit{
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}

.site-credit{

	a{
		color: #fff;
	}
}

@media (min-width: 769px) {
	.copyright{
		text-align: left;
	}
	.site-credit{
		text-align: right;
	}
}



// ----------------------
// BREADCRUMB
// ----------------------

#breadcrumbs{
	margin-top: 10px;

	.breadcrumb{
		padding-left: 0;
		font-size: 14px;
		background-color: transparent;
	}
}

// ----------------------
// MODAL
// ----------------------

.modal-success{
	.modal-content{
		color: #3c763d;
	    background-color: #dff0d8;
	    border-color: #d6e9c6;
	}
}

.modal-warning{
	.modal-content{
		color: #8a6d3b;
	    background-color: #fcf8e3;
	    border-color: #faebcc;
	}
}

.modal-danger{
	.modal-content{
		color: #a94442;
	    background-color: #f2dede;
	    border-color: #ebccd1;
	}
}


// ----------------------
// PAGE
// ----------------------

.page-title{
	font-size: 30px;
	font-weight: bold;
	margin-top: 0;
	margin-bottom: 5px;
	color: $base-color;
}

.article-header{
	border-bottom: 3px solid $base-color;
	margin-bottom: 30px;
}

.entry-content{
	margin-bottom: 20px;
}

.page-content{
	margin-bottom: 30px;
}


// ----------------------
// Widget
// ----------------------

.widget{
	padding: 0;
	border-top-width: 0;

	ul{
		padding-left: 17px;
	}
}

.widget-content{
	padding: 5px 20px 20px;
}

.widget-title{
	margin-top: 0;
	padding: 10px 20px;
	background-color: $base-color;
	color: #fff;
	font-size: 20px;
}

.widget-inner-title{
	color: $base-color;
	font-weight: bold;
}


// ----------------------
// ATTACHMENT CSS
// ----------------------

.progress {
	height:auto;
	padding: 3px;

	.bar {
		background-color: #8be08b;
		width: 0%;
		height: 19px;
		border-radius: 3px;	 
	}
	.percent {
		position: absolute;
		display: inline-block;
		top: 3px;
		left: 48%;
	}
}

// ----------------------
// UTILITY CSS
// ----------------------

.mt-5{
	margin-top: 5px;
}

.mt-10{
	margin-top: 10px;
}

.mt-15{
	margin-top: 15px;
}

.mt-20{
	margin-top: 20px;
}

.mb-5{
	margin-bottom: 5px;
}

.mb-10{
	margin-bottom: 10px;
}

.mb-15{
	margin-bottom: 15px;
}

.mb-20{
	margin-bottom: 20px;
}

.no-margin{
	margin: 0;
}

.text-bold{
	font-weight: 700;
}


// SEARCH PAGE

.search-container{
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}

#search-result{
	margin: 15px;
	padding: 10px;

	address{
		margin-bottom: 5px;
	}
}
//arif kms
.btn-add-new{
	background-color: #1E90FF;
	border-color: #1E90FF;

	&:hover{
		background-color: darken(#1E90FF, 10%);
		border-color: darken(#1E90FF, 10%);
	}
}

.panel.panel-default-inner{
	& > .panel-heading{
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: #ededed;
		color: #333;
	}
}

.remove-row-institution-details{
	padding-top: 0;
	padding-bottom: 0;
}

/** MAP **/

#mapCanvas{
	width: 100%;
	min-height: 200px;
	max-height: 400px;
}
